import React from 'react';

export default function Logo(props) {
	return (
		<svg
			{...props}
			viewBox="0 0 24 24"
			fill="currentColor"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M5.0408 9.13445C3.91037 8.00402 3.91037 6.17123 5.0408 5.0408C6.17123 3.91037 8.00402 3.91037 9.13445 5.0408C10.2649 6.17123 10.2649 8.00402 9.13445 9.13445C8.00402 10.2649 6.17123 10.2649 5.0408 9.13445Z" />
			<path d="M9.95318 14.0468C8.82275 12.9164 8.82275 11.0836 9.95318 9.95318C11.0836 8.82275 12.9164 8.82275 14.0468 9.95318C15.1773 11.0836 15.1773 12.9164 14.0468 14.0468C12.9164 15.1773 11.0836 15.1773 9.95318 14.0468Z" />
			<path d="M8.72508 2.99397C8.27291 2.5418 8.27291 1.80869 8.72508 1.35652C10.5338 -0.452172 13.4662 -0.452172 15.2749 1.35652C15.7271 1.80869 15.7271 2.5418 15.2749 2.99397L12.8187 5.45016C12.3666 5.90233 11.6334 5.90233 11.1813 5.45016L8.72508 2.99397Z" />
			<path d="M1.35652 15.2749C-0.452171 13.4662 -0.452171 10.5338 1.35652 8.72508C1.80869 8.27291 2.5418 8.27291 2.99398 8.72508L5.45016 11.1813C5.90234 11.6334 5.90234 12.3666 5.45016 12.8187L2.99398 15.2749C2.5418 15.7271 1.80869 15.7271 1.35652 15.2749Z" />
			<path d="M13.6375 7.90635C13.1853 7.45418 13.1853 6.72106 13.6375 6.26889L16.0936 3.8127C16.5458 3.36053 17.2789 3.36053 17.7311 3.8127L20.1873 6.26889C20.6395 6.72106 20.6395 7.45418 20.1873 7.90635L17.9358 10.1579C17.3706 10.7231 16.4542 10.7231 15.889 10.1579L13.6375 7.90635Z" />
			<path d="M3.81271 17.7311C3.36053 17.2789 3.36053 16.5458 3.81271 16.0936L6.26889 13.6375C6.72107 13.1853 7.45418 13.1853 7.90635 13.6375L10.3625 16.0936C10.8147 16.5458 10.8147 17.2789 10.3625 17.7311L8.11104 19.9826C7.54582 20.5478 6.62943 20.5478 6.06421 19.9826L3.81271 17.7311Z" />
			<path d="M18.5498 12.8187C18.0977 12.3666 18.0977 11.6334 18.5498 11.1813L21.006 8.72508C21.4582 8.27291 22.1913 8.27291 22.6435 8.72508C24.4522 10.5338 24.4522 13.4662 22.6435 15.2749C22.1913 15.7271 21.4582 15.7271 21.006 15.2749L18.5498 12.8187Z" />
			<path d="M8.72508 22.6435C8.27291 22.1913 8.27291 21.4582 8.72508 21.006L11.1813 18.5498C11.6334 18.0977 12.3666 18.0977 12.8187 18.5498L15.2749 21.006C15.7271 21.4582 15.7271 22.1913 15.2749 22.6435C13.4662 24.4522 10.5338 24.4522 8.72508 22.6435Z" />
		</svg>
	);
}
